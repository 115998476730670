import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/home/runner/work/portal-ux/portal-ux/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/home/runner/work/portal-ux/portal-ux/src/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/portal-ux/portal-ux/src/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/portal-ux/portal-ux/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts.ts\",\"import\":\"Work_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/portal-ux/portal-ux/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts.ts\",\"import\":\"Playfair_Display\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-serif\"}],\"variableName\":\"fontSerif\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/portal-ux/portal-ux/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts.ts\",\"import\":\"Lalezar\",\"arguments\":[{\"weight\":[\"400\"],\"subsets\":[\"latin\"],\"variable\":\"--font-lalezar\"}],\"variableName\":\"lalezar\"}");
